// 会員園向けのアンケートページ

<template lang="pug">
el-card.member-survey

  .go-back(style='margin-bottom: 0.5rem')
    el-button(type='text', icon='el-icon-back', @click='$router.go(-1)') 戻る

  article.surveys(v-if='surveys.length > 0')
    p 回答するアンケートをクリックしてください。
    ul.surveys
      li(v-for='item in surveys', :class='{ "not-answered": !item.answerId, finished: !item.isActive }')
        a(@click='clickSurvey(item)')
          el-row(type='flex', align='middle', :gutter='20')
            el-col.status(:span='3')
              el-tag(:type='item.isActive ? "" : "info"') {{ item.isActive ? '受付中' : '終了' }}
            el-col(:span='18')
              .title {{ item.title }}
              .end {{ item.end | jpDate }}まで
            el-col.condition(:span='3')
              p {{ item.answerId ? '回答済' : '未回答' }}

  p(v-else) 現在アンケートはありません。

</template>

<script>
import getSurveyListApi from '@/api/get-survey-list'

export default {
  name: 'MemberSurvey',

  data() {
    return {
      surveys: () => [],
    }
  },

  async created() {
    const response = await getSurveyListApi()
    if (!response.ok) return
    this.surveys = response.payload.items
  },

  methods: {
    clickSurvey(item) {
      this.$router.push({
        name: 'MemberSurveyForm',
        params: {
          surveyId: item.id,
        },
      })
    },
  },
}
</script>

<style lang="sass" scoped>
.member-survey

  article.surveys
    min-height: 300px

    ul
      margin-top: 1rem
      border-bottom: 1px solid $color-1
      list-style-type: none
      li
        border-top: 1px solid $color-1
        a
          text-decoration: none
          display: block
          padding: 0.5rem
          cursor: pointer
          color: $grey-dark
          .title
            font-weight: bold
            font-size: 1.2rem
            letter-spacing: 1px
          .end
            font-size: 0.95rem
          .status
            text-align: center
          .condition
            text-align: center
            p
              color: #0068b7
              font-weight: bold
        &.finished
          a
            background: #f3f3f3
        &.not-answered
          a
            .condition
              p
                color: #b70000
        &:not(.finished)
          a:hover
            background: lighten($color-1, 40%)
</style>
