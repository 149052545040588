// 自分宛てのアンケートの取得

import api from './api'

const path = '/surveys'

export default (token = null) => {
  return api({
    url: path,
    auth: true,
    token,
  })
}
